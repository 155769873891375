<template>
  <div>
    <master-component
      :config="config"
      :showRemove="false"
      :showAdd="false"
      @results-search="sumTotalCC($event)"
    >

      <template v-slot:table-other-button>
        <span class="title red--text mt-8 mr-12"> {{ totalCC | currency(0) }} cc</span>

        <!-- <v-btn
          color="error"
          @click="deleteAllData('โอ๊ต')"
          v-if="isAdmin"
        >ลบข้อมูลผู้ใช้ชื่อ โอ๊ต ทั้งหมด</v-btn>
        <v-btn
          color="error"
          @click="deleteAllData('ติ๊ก')"
          v-if="isAdmin"
        >ลบข้อมูลผู้ใช้ชื่อ ติ๊ก ทั้งหมด</v-btn> -->
      </template>

      <template v-slot:table-custom-event-button="{ item }">
        <custom-tooltip
          v-slot="{ on }"
          title="ลบข้อมูลผู้บริจาครายนี้ทั้งหมด"
        >
          <v-icon
            v-on="on"
            color="info"
            @click="clearAllData(item)"
            class="mx-5"
            v-if="isAdmin"
          >mdi-delete</v-icon>
        </custom-tooltip>
      </template>
    </master-component>
  </div>
</template>


<script>
import { getLocations } from "../js/dropdown-service";
import { UserRole, InputType, DialogSize, Usr } from "../js/constants";
import { getToken } from "../js/authorization";
import { sumList } from "../js/custom";

export default {
  data() {
    return {
      config: {},
      totalCC: 0,
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    initialConfig() {
      this.config = {
        name: "history",
        shortName: "ประวัติการบริจาคโลหิต",
        search: this.searchSection,
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection,
      };
    },
    clearAllData(item) {
      let url = `/checkin/${item.id}/ClearAllCheckInData`;
      this.$http.delete(url).then((res) => {
        this.showAlertSuccess();
      });
    },
    deleteAllData(targetText) {
      let url = `/checkin/ClearAllCheckInData?targetText=${targetText}`;
      this.$http.delete(url).then((res) => {
        this.showAlertSuccess();
      });
    },
    sumTotalCC(items) {
      this.totalCC = sumList(items, "toTalBloodCC");
    },
  },
  computed: {
    urlSection() {
      let searchUrl = "/checkin/history/search";
      let getUrl = "/checkin/history";
      let addUrl = "";
      let editUrl = "/checkin/history";
      let removeUrl = "";

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl,
      };
    },
    searchSection() {
      return {
        searchOnInit: true,
        rows: [
          [
            {
              name: "university",
              text: "สถานศึกษา",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/universities",
              },
              columnClass: "col-12 col-md-6",
            },
            {
              name: "team",
              text: "ทีม",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/teams",
              },
              columnClass: "col-12 col-md-6",
            },
          ],
          [
            {
              name: "name",
              text: "Name",
              type: InputType.text,
              columnClass: "col-12 col-md-4",
            },
            {
              name: "checkInDate",
              text: "ช่วงวันที่บริจาค",
              type: InputType.begin_end_date,
              default: {
                begin: "01/01/2567",
                end: "31/12/2567",
              },
              columnClass: "col-12 col-md-8",
            },
            {
              type: InputType.offset,
            },
          ],
        ],
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN, UserRole.MENTOR],
        edit: [UserRole.ADMIN, UserRole.MENTOR],
        remove: [],
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "checkInDate",
            text: "วันที่",
            style: {
              width: "100px",
            },
          },
          {
            name: "fullName",
            text: "Name",
            style: {
              width: "160px",
            },
          },
          {
            name: "university.text",
            text: "สถานศึกษา",
            style: {
              width: "230px",
            },
          },
          {
            name: "teamName",
            text: "ชื่อทีม",
            style: {
              width: "160px",
            },
          },
        ],
      };
    },
    formSection() {
      return {
        size: DialogSize.fullScreen,
        rows: [
          [
            {
              name: "checkInDate",
              text: "วันที่",
              type: InputType.label,
              columnClass: "col-12 col-md-3",
            },
          ],
          [
            {
              name: "namePrefix",
              labelPath: "text",
              text: "คำนำหน้าชื่อ",
              type: InputType.label,
              columnClass: "col-12 col-md-3",
            },
            {
              name: "firstNameThai",
              text: "ชื่อ (ภาษาไทย)",
              type: InputType.label,
              columnClass: "col-12 col-md-3",
            },
            {
              name: "lastNameThai",
              text: "นามสกุล (ภาษาไทย)",
              type: InputType.label,
              columnClass: "col-12 col-md-3",
            },
          ],
          [
            {
              name: "teamName",
              text: "ทีม",
              type: InputType.label,
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "code",
              text: "TeamCode",
              type: InputType.label,
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "university",
              labelPath: "text",
              text: "สถานศึกษา",
              type: InputType.label,
              columnClass: "col-12 col-md-6",
            },
            {
              name: "bloodGroup",
              labelPath: "text",
              text: "กรุ๊ปเลือด",
              type: InputType.label,
              columnClass: "col-12 col-md-3",
            },
            {
              name: "weight",
              text: "น้ำหนัก(กิโลกรัม)",
              type: InputType.number,
              decimal: 0,
              rule: {
                required: true,
                min_value: 40,
                max_value: 200,
              },
              columnClass: "col-12 col-md-3",
            },
          ],
          [
            {
              name: "team",
              text: "โค้ดผู้แนะนำ",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/teams?onlyComplete=true",
              },
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-3",
              showIf: ({ model }) => {
                return model.canEditTeam;
              },
            },
          ],
          [
            {
              type: "section",
              text: "อัพโหลดหลักฐานการบริจาคโลหิต (บัตรบริจาคโลหิต โดยให้เห็นชื่อ, วันที่, และลายเซ็นต์เจ้าหน้าที่)",
            },
          ],
          [
            {
              name: "imageList",
              default: [],
              type: InputType.historyGallery,
            },
          ],
        ],
      };
    },
  },
};
</script>
